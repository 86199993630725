import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import CallUsCallout from '~2-components/CallUsCallout/CallUsCallout';
import CallUsCalloutKontent from '~3-data-components/CallUsCalloutKontent';
import { useStaticQuery, graphql } from "gatsby";

const CallUsCalloutEg = `import CallUsCallout from '~2-components/CallUsCallout/CallUsCallout';

<CallUsCallout>
    // Content goes here
</CallUsCallout>
`;

const SgCallUsCallout = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allCallUsCallouts = useStaticQuery(
        graphql`
        query SGAllCallUsCallouts {
            allKontentItemCallUsCallout {
                nodes {
                    system {
                        id
                    }
                    elements {
                        call_us_callout {
                            value
                        }
                    }
                }
            }
        }
    `);

    const allCallouts = allCallUsCallouts.allKontentItemCallUsCallout.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Call Us Callout</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={CallUsCalloutEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <CallUsCallout>
                        <p>Call us on <strong>(03) 9321 9988</strong> and <strong>book your free appointment</strong> for preliminary advice about your rights and possible entitlements.</p>
                    </CallUsCallout>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent call us callouts</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allCallouts.map((item, i) => {
                        return <CallUsCalloutKontent key={i} {...item}/>;
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgCallUsCallout;
